<template>
  <div class="box">
    <div class="headline">检查流程</div>
    <div class="second_line">5.3 考核细则</div>
    <span class="text">
      点击左侧【文件落地检查】-【考核细则】，进入到文件检查列表，如下图。
      图中列表显示的就是所有文件检查的详细数据，其中可清楚的看到该检查的检查状态。
      新做一考核细则。 点击“抽取题目”，弹窗如下：
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/34.png')"
      :preview-src-list="[require('../../assets/Bankimg/34.png')]"
    >
    </el-image>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/35.png')"
      :preview-src-list="[require('../../assets/Bankimg/35.png')]"
    >
    </el-image>
    <span class="text">
      可以分四次抽完，每次抽题的数量必须要达到总题数的37%，抽完则直接进去检查表页面，也可以通过双击计划页面的某一项进入该页面。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/36.png')"
      :preview-src-list="[require('../../assets/Bankimg/36.png')]"
    >
    </el-image>
    <span class="text">
      检查详情界面可判断该检查项是否符合要求，再上传相应的图片，最后双击“审核意见”单元格并输入《审核意见》，完成对该检查项的审核，完成所有检查项的审核后再点击“完成检查”完成对该考核细则的检查。点符合就会直接通过，否则会弹出如下图，输入《审核意见》，上传图片，再点击“确认”即可。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/37.png')"
      :preview-src-list="[require('../../assets/Bankimg/37.png')]"
    >
    </el-image>
    <span class="text"> 点击“打印检查表”弹出如下图，根据需要可自行打印。 </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/38.png')"
      :preview-src-list="[require('../../assets/Bankimg/38.png')]"
    >
    </el-image>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      srcList: "require('../../assets/Bankimg/1.jpg')",
    };
  },
};
</script>
    
<style  lang="less" scoped>
.box {
  padding-left: 40px;
  .headline {
    font-size: 28px;
    color: #1f2d3d;
    padding-top: 28px;
    line-height: 45px;
    padding-left: 0px ;
  }
  .second_line {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 0 12px;
  }
  .text {
    color: #141e31;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  img {
  }
}
</style>